import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/site-wide/layout/layout";
import Divider from "../components/common/divider";

export default () => {
  return (
    <>
      <Layout title="Политика за бисквитките">
        <Container className="mt-5 pt-5">
          <div className="text-center mb-5">
            <h1>Политика за бисквитките</h1>
            <Divider />
          </div>

          <div className="mb-5">
            <div>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Този документ съдържа политиката на{" "}
                  </span>
                </em>
                <strong>
                  <em>Локсмит Експерт ООД</em>
                </strong>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    при използване на бисквитки в уеб страницата
                  </span>
                </em>
                <a href="https://klucharite.bg/">
                  <span style={{ fontWeight: 400 }}>https://klucharite.bg</span>
                </a>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp; и прилежащите й подстраници.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Бисквитките ("cookies") са малки текстови файлове, които се
                    съхраняват на Вашия компютър, когато посещавате някои web
                    страници.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>Сайта на </span>
                </em>
                <strong>
                  <em>Локсмит Експерт ООД</em>
                </strong>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    използва бисквитки, за да подобри представянето на
                    информацията и да предостави персонализирани, релавантни и
                    надеждни услуги на потребителите.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Може да забраните използването на бисквитки в настройките на
                    Вашия web браузър, но това ще повлияе негативно на
                    функционалността на този уеб сайт.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Тази страница използва няколко категории постоянни бисквитки
                    ("persistent cookies"). Всички бисквитки се генерират и
                    използват от страна на{" "}
                  </span>
                </em>
                <strong>
                  <em>Локсмит Експерт ООД</em>
                </strong>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    ("first-party cookies"), с изключение на бисквитки за
                    статистически цели на Google ("third-party cookies").
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <strong>
                  <em>Функционални бисквитки</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Функционалните бисквитки ни позволяват да предложим
                    допълнителни възможности за употреба на този уеб сайт.
                  </span>
                </em>
              </p>
              <ul>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Съдържат техническа, неперсонализираща информация за
                    устройството за достъп, което използвате (вид и версия на
                    браузъра, операционната система, размерите на прозореца,
                    резолюцията на дисплея, поддържаните технологии и др.). Тази
                    информация ни позволява да визуализираме качествено и
                    адаптивно съдържанието на тази страница.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    Позволяват сигурна и надеждна оторизация при проверяване на
                    Вашата поръчка и извършване на поръчка&nbsp;
                  </span>
                </li>
              </ul>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Информацията, получена от функционалните бисквитки, не се
                    споделя с трети лица.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <strong>
                  <em>Статистически/Проследяващи бисквитки</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Тези бисквитки се използват за проследяване на страниците,
                    които посещавате в този уеб сайт. Информацията, която
                    съхраняваме, се агрегира и не съдържа никакви лични данни.
                    Данните се използват за статистически и аналитични цели,
                    които ни позволяват да предложим по-добри услуги и продукти
                    на нашата аудитория, както и по-релевантна информация по
                    време на Вашето използване на тази страница.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Този сайт използва Google Analytics за статистически цели.
                    Споделената статистическа информация с Google не съдържа
                    никакви лични и/или други персонализирани данни. Тази
                    информация е обект на
                  </span>
                </em>
                <a href="http://www.google.bg/intl/bg/policies/privacy/">
                  <em>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      политиката за конфиденциалност на Google
                    </span>
                  </em>
                </a>
                <em>
                  <span style={{ fontWeight: 400 }}>.</span>
                </em>
              </p>
              <p>
                <strong>
                  <em>&nbsp;Реклама и анализи</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Използваме технологии, като „бисквитки“ и уеб маяк, за
                    да персонализираме съдържанието и рекламите, да предоставяме
                    функции за социални медии и да анализираме трафика към
                    сайта. Също така споделяме информация за използването на
                    нашия сайт от нашите доверени социални медии, рекламни и
                    аналитични партньори. Ние използваме тези „бисквитки“, за да
                    ви показваме реклами, които могат да имат отношение към вас
                    и вашите интереси. Информацията може да се използва и за
                    целите на ограничаване на честотата (например, за да
                    гарантираме, че не ви показваме многократно една и съща
                    реклама), и да помогнем да регулирате рекламите, които
                    получавате, и да измервате тяхната ефективност.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <strong>
                  <em>Реклами за потребителска аудитория във Facebook</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Възможно е да ви показваме реклами въз основа на интереси,
                    когато използвате Facebook чрез инструмент предлаган от
                    Facebook, наречен Инструмент за потребителска аудитория.
                    Този инструмент ни позволява да персонализираме рекламите си
                    въз основа на опита Ви с нас при пазаруване. Ние не
                    споделяме нито една ваша лична информация, включително
                    историята на пазаруването Ви с Facebook. Инструментът ни
                    позволява да преобразуваме вашия имейл адрес в уникален
                    номер, който Facebook използва, за да съответства на
                    уникалните номера, които Facebook генерира от имейл адресите
                    на своите потребители.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <strong>
                  <em>Персонализиран пиксел за аудитория</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Персонализираният пиксел за аудитория е малка част от
                    JavaScript код, който сме включили във всяка от нашите уеб
                    страници. Тази част от кода предоставя серия от функции за
                    предаване на специфични за приложението събития и дефинирани
                    от потребителя данни към Facebook. Ние използваме
                    потребителски пиксели за аудитория, за да записваме
                    информация за начина, по който посетителите използват нашия
                    уеб сайт. Поради тази причина всяка от нашите уеб страници
                    съдържа потребителски пиксел за аудиторията. Този пиксел
                    записва информация за сесията на браузъра на потребителя,
                    която изпраща до Facebook, заедно с хеширана версия на
                    идентификатора на Facebook и разглеждания връзка. Всеки
                    потребител на Facebook има уникален, независим от
                    устройството Facebook ID, който ни позволява да адресираме и
                    разпознаваме потребители в различни устройства, използващи
                    социалната мрежа във Facebook, така че да можем да
                    адресираме нашите посетители за търговски цели, използвайки
                    Facebook реклами. Информацията за потребителя ще бъде
                    изтрита след 180 дни, докато потребителят отново посети
                    нашия уебсайт. „Персонализираната аудитория от уебсайта Ви“
                    уважава поверителността Ви. По този начин няма да се
                    разкриват лични данни за отделните посетители на уебсайта и
                    ние можем да рекламираме само целеви групи на уебсайта по
                    целесъобразен начин, след като целевата група на клиента
                    достигне критична маса. Това прави невъзможно да открием
                    самоличността на отделните посетители.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <strong>
                  <em>Facebook Пиксел</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Проследяването на реализациите ни помага да измерваме
                    възвращаемостта на инвестициите на рекламите в Facebook,
                    като съобщаваме за действията, които хората предприемат след
                    преглеждане на тези реклами. Създаваме Facebook пиксели,
                    които проследяват реализациите, ги добавяме към страниците
                    на уеб сайта ни, където се случват реализациите, и след това
                    ги проследяваме обратно към рекламите в Facebook. Никаква
                    лична информация не се съдържа в или се събира в резултат на
                    използването на тези бисквитки или пиксели.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <strong>
                  <em>Управлявайте „бисквитките“ и други предпочитания</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </em>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Много уеб браузъри ви позволяват да управлявате
                    предпочитанията си. Можете да настроите браузъра си да
                    отказва „бисквитки“ или да изтрива определени „бисквитки“.
                    Възможно е да можете да управлявате други технологии по
                    същия начин, по който управлявате бисквитки, като използвате
                    настройките&nbsp; на браузъра си.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Моля, имайте предвид, че ако решите да блокирате
                    „бисквитките“, това може да навреди на уебсайта или да
                    предотврати функционирането на някои елементи от него. Не е
                    приет стандарт за това как да се реагира на сигнали "Не
                    проследявайте" и не отговаряме на такива сигнали.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </em>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    На мобилното ви устройство може да имате и настройка
                    „Ограничаване на проследяването на рекламите“ (iOS™) или
                    настройка „Изключване от реклами въз основа на интереси“ (
                    Android™), която ви позволява да ограничите използването на
                    информация за употребата си от приложения за целите на
                    показването на реклами, насочени към интересите ви.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Моля, имайте предвид, че дори да се откажете от използването
                    на механизмите по-горе, все още можете да получавате
                    реклами, когато използвате нашите услуги.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Работим с рекламни партньори, за да показваме онлайн
                    реклами. Една от услугите, които използваме за проследяване
                    на дейностите, например чрез поставяне на „бисквитки“, е
                    Google Analytics от Google (Google, Inc., компания със
                    седалище в Амфитеатър Парквай, 1600, Маунт Вю, CA 94043,
                    САЩ). Можем да използваме доставчици, включително Google,
                    които използват „бисквитки“ от първа страна (като
                    „бисквитката“ на Google Анализ) и „бисквитки“ на трети
                    страни (като „бисквитката“ на DoubleClick), за да
                    информират, оптимизират и показват реклами въз основа на
                    предишните ви посещения в уебсайтове, включително Google
                    Анализ за показване на реклама. Google предоставя
                    инструменти за управление на събирането и използването на
                    определена информация от Google Анализ на
                    tools.google.com/dlpage/gaoptout и от Google Анализ за
                    показване на реклама.
                  </span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Вие се съгласявате с използването на „бисквитките“ и други
                    технологии, описани по-горе.
                  </span>
                </em>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>
                  <em>Условия за ползване</em>
                </strong>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Продължаващото използване от Ваша страна на тази страница
                    означава съгласие с политиката за използване на бисквитки на{" "}
                  </span>
                </em>
                <strong>
                  <em>Локсмит Експерт ООД</em>
                </strong>
                <em>
                  <span style={{ fontWeight: 400 }}>.</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>&nbsp;</span>
                </em>
              </p>
              <p>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    Този сайт спазва Вашите потребителски права, съгласно
                    изискванията и ограниченията на
                  </span>
                </em>
                <a href="http://eur-lex.europa.eu/legal-content/BG/TXT/HTML/?uri=CELEX:32002L0058&from=EN">
                  <em>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      Директива 2002/58/ЕО
                    </span>
                  </em>
                </a>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    на Европейския съюз както и{" "}
                  </span>
                </em>
                <strong>
                  <em>Регламент ЕС 2016/679 (GDPR)</em>
                </strong>
                <em>
                  <span style={{ fontWeight: 400 }}>.</span>
                </em>
              </p>
            </div>
          </div>
        </Container>
      </Layout>
    </>
  );
};
